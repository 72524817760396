import React, { useState, useEffect, useRef } from 'react'
import { Route } from 'react-router-dom'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import GoogleAnalyticsReporter from './components/analytics/GoogleAnalyticsReporter'
import { useActiveWeb3React } from './hooks'
import Web3ReactManager from './components/Web3ReactManager'
import styled from 'styled-components'
import { BigNumber } from '@ethersproject/bignumber'
import './App.css'
import ShibavaxHeader from './components/Header'
import Web3Status from './components/Web3Status'
import { SHIBAVAX_CONTRACT_ADDRESS, SHIBAVAX_ABI, HUNT_CONTRACT_ADDRESS, HUNT_CONTRACT2_ADDRESS, HUNT_ABI } from './config'
import { useContract } from './hooks/useContract'
import axios from 'axios'
import ReactAudioPlayer from 'react-audio-player';

const ONE_SHIBX = BigNumber.from('1000000000000000000')
const ONE_K_SHIBX = BigNumber.from('1000000000000000000').mul(1000)
const API_URL = 'https://api-hunt.shibavax.io'

const Header = styled.div`
  font-family: Montserrat;
  font-style: normal;
  font-weight: 900;
  font-size: 80px;
  line-height: 97.52px;
  text-transform: capitalize;
  color: #E84142;
`

const AdjustedCol = styled(Col)`
  display: flex;
  margin-left: 0;
  margin-right: 0;
  align-items: center;
  width: 100%;
`

const AdjustedRow = styled(Row)`
  margin-left: 0;
  margin-right: 0;
  width: 100%;
`

const RiddleTitle = styled(Header)`
  font-size: 60px;
  line-height: 73.14px;
`

const Regular = styled.div`
  font-family: MontSerrat;
  font-style: normal;
  font-size: 27px;
  font-weight: 500;
  line-height: 37px;
  text-align: center;
  color: #000000;
`

const RegularRed = styled(Regular)`
  color: #E84142;
`

const Divider = styled.hr`
  margin-top: 2%;
  margin-bottom: 2%;
  width: 75%;
  border-top: 1px solid #CBC8C9;
`

const AppWrapper = styled(Container)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 40px;
  width: 100%;
`

const RedButton = styled(Button)`
  border: 1px solid #FFFFFF;
  border-radius: 82px;
  background: #E84142;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 47px;
  color: #FFFFFF;
`

const Input = styled.input<{ error?: boolean }>`
  font-size: 1.25rem;
  outline: none;
  ::text-area {
    border: 1px solid #E84142;
    border-radius: 82px;
  }
  flex: 1 1 auto;
  width: 0;
  transition: color 300ms ${({ error }) => (error ? 'step-end' : 'step-start')};
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  font-weight: 500;
  width: 100%;
  padding: 0px;
  -webkit-appearance: textfield;

  ::-webkit-search-decoration {
    -webkit-appearance: none;
  }

  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  ::placeholder {
    text-align: center;
  }
`

interface RiddleParams {
  address: string
  riddle_id: number
  answer: string
  signature: string
}

interface RiddleData {
  id: number
  text: string
  url: string
  url_type: string
  hint: boolean
  hint2: boolean
}

interface HintParams {
  address: string
  riddle_id: number
  signature: string
  nonce: string
}

function makeNonce() {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < 32) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
}

const defaultRiddleData = {
  id: 0,
  text: "",
  url: "",
  url_type: "none",
  hint: false,
  hint2: false,
}

const App = () => {
  const { account, library } = useActiveWeb3React()
  const [ riddleAnswer, setRiddleAnswer ] = useState<string>('')
  const [ resultMessage, setResultMessage ] = useState('')
  const [ hintMessage, setHintMessage ] = useState('')
  const [ hint2Message, setHint2Message ] = useState('')
  const [ hasPurchasedHint, setHasPurchasedHint ] = useState<boolean>(false)
  const [ hasPurchasedHint2, setHasPurchasedHint2 ] = useState<boolean>(false)
  const [ hintPrice, setHintPrice ] = useState<BigNumber>(BigNumber.from(0))
  const [ hint2Price, setHint2Price ] = useState<BigNumber>(BigNumber.from(0))
  const [ riddleData, setRiddleData ] = useState<RiddleData>(defaultRiddleData)
  const [ shibxAllowance, setShibxAllowance ] = useState<BigNumber>(BigNumber.from(0))
  const [ shibxAllowance2, setShibxAllowance2 ] = useState<BigNumber>(BigNumber.from(0))
  const [ checkAllowance, setCheckAllowance ] = useState<boolean>(false)
  const [ checkAllowance2, setCheckAllowance2 ] = useState<boolean>(false)
  const [ checkHasPurchasedHint, setCheckHasPurchasedHint ] = useState<boolean>(false)
  const [ checkHasPurchasedHint2, setCheckHasPurchasedHint2 ] = useState<boolean>(false)
  const [ shibxBalance, setShibxBalance ] = useState<BigNumber>(BigNumber.from(0))

  const answerButtonRef = useRef<HTMLInputElement>(null)
  const hintButtonRef = useRef<HTMLInputElement>(null)
  const hint2ButtonRef = useRef<HTMLInputElement>(null)

  const huntContract = useContract(HUNT_CONTRACT_ADDRESS, HUNT_ABI, true)
  const huntContract2 = useContract(HUNT_CONTRACT2_ADDRESS, HUNT_ABI, true)
  const shibavaxContract = useContract(SHIBAVAX_CONTRACT_ADDRESS, SHIBAVAX_ABI, true)

  useEffect(() => {
      if (account) {
        shibavaxContract?.balanceOf(account)
          .then((balance: any) => {
            setShibxBalance(balance.div(ONE_SHIBX))
          })
          .catch((error: any) => {
            console.log(error)
          })
      }
    }, [account, shibavaxContract])

  useEffect(() => {
      if (account && shibxBalance.gte(BigNumber.from(500000))) {
        axios.get(API_URL + '/treasure-hunt/riddle')
          .then(response => {
            setRiddleData(response.data.riddle)
          })
      }
    }, [account, shibxBalance])

  useEffect(() => {
      if (riddleData.hint) {
        huntContract?.hasPurchasedHint(account, BigNumber.from(riddleData.id))
          .then((purchased: boolean) => {
            setHasPurchasedHint(purchased)
          })
          .catch((error: any) => {
            console.log(error)
          })
        huntContract?.hintPrices(BigNumber.from(riddleData.id))
          .then((price: BigNumber) => {
            setHintPrice(price)
          })
          .catch((error: any) => {
            console.log(error)
          })
        shibavaxContract?.allowance(account, HUNT_CONTRACT_ADDRESS)
          .then((allowance: BigNumber) => {
            setShibxAllowance(allowance)
          })
          .catch((error: any) => {
            console.log(error)
          })
      }
    }, [account, huntContract, shibavaxContract, riddleData])

  useEffect(() => {
      if (riddleData.hint2) {
        huntContract2?.hasPurchasedHint(account, BigNumber.from(riddleData.id))
          .then((purchased: boolean) => {
            setHasPurchasedHint2(purchased)
          })
          .catch((error: any) => {
            console.log(error)
          })
        huntContract2?.hintPrices(BigNumber.from(riddleData.id))
          .then((price: BigNumber) => {
            setHint2Price(price)
          })
          .catch((error: any) => {
            console.log(error)
          })
        shibavaxContract?.allowance(account, HUNT_CONTRACT2_ADDRESS)
          .then((allowance: BigNumber) => {
            setShibxAllowance2(allowance)
          })
          .catch((error: any) => {
            console.log(error)
          })
      }
    }, [account, huntContract2, shibavaxContract, riddleData])

  // Refresh allowance periodically after an approve transaction
  useEffect(() => {
    if (checkAllowance) {
      let currentAllowance = shibxAllowance
      const intervalId = setInterval(() => {
        shibavaxContract?.allowance(account, HUNT_CONTRACT_ADDRESS)
          .then((allowance: BigNumber) => {
            if (!allowance.eq(currentAllowance)) {
              setShibxAllowance(allowance)
              setCheckAllowance(false)
            }
          })
          .catch((error: any) => {
            console.log(error)
          })
      }, 2000)

      return () => clearInterval(intervalId)
    }
  }, [account, shibavaxContract, shibxAllowance, checkAllowance])

  useEffect(() => {
    if (checkAllowance2) {
      let currentAllowance = shibxAllowance2
      const intervalId = setInterval(() => {
        shibavaxContract?.allowance(account, HUNT_CONTRACT2_ADDRESS)
          .then((allowance: BigNumber) => {
            if (!allowance.eq(currentAllowance)) {
              setShibxAllowance2(allowance)
              setCheckAllowance2(false)
            }
          })
          .catch((error: any) => {
            console.log(error)
          })
      }, 2000)

      return () => clearInterval(intervalId)
    }
  }, [account, shibavaxContract, shibxAllowance2, checkAllowance2])

  // Refresh hasPurchaseHint after a purchase transaction
  useEffect(() => {
    if (checkHasPurchasedHint) {
      const intervalId = setInterval(() => {
        huntContract?.hasPurchasedHint(account, BigNumber.from(riddleData.id))
          .then((purchased: boolean) => {
            setHasPurchasedHint(purchased)
            if (hasPurchasedHint) {
              setCheckHasPurchasedHint(false)
            }
          })
          .catch((error: any) => {
            console.log(error)
          })
      }, 2000)

      return () => clearInterval(intervalId)
    }
  }, [account, huntContract, hasPurchasedHint, checkHasPurchasedHint, riddleData.id])

  // Refresh hasPurchaseHint2 after a purchase transaction
  useEffect(() => {
    if (checkHasPurchasedHint2) {
      const intervalId = setInterval(() => {
        huntContract2?.hasPurchasedHint(account, BigNumber.from(riddleData.id))
          .then((purchased: boolean) => {
            setHasPurchasedHint2(purchased)
            if (hasPurchasedHint2) {
              setCheckHasPurchasedHint2(false)
            }
          })
          .catch((error: any) => {
            console.log(error)
          })
      }, 2000)

      return () => clearInterval(intervalId)
    }
  }, [account, huntContract2, hasPurchasedHint2, checkHasPurchasedHint2, riddleData.id])


  const handleHintClick = () => {
    if (hasPurchasedHint) {
      if (account && account != null && library) {
        const nonce = makeNonce()
        library
          .send('personal_sign', [account, `${Buffer.from(nonce, 'utf8').toString('hex')}`])
          .then(signature => {
            const params : HintParams = {
              address: account,
              riddle_id: riddleData.id,
              signature: signature,
              nonce: nonce,
            }
            axios.post(API_URL + '/treasure-hunt/hint', params)
              .then(response => {
                setHintMessage(response.data.hint)
                setResultMessage(response.data.result)
                if (hintButtonRef.current) {
                  hintButtonRef.current.blur()
                }
              })
          })
      }
    } else {
      if (hintPrice && !hintPrice.isZero()) {
        if (shibxAllowance.lt(hintPrice)) {
          // approve
          shibavaxContract?.approve(HUNT_CONTRACT_ADDRESS, hintPrice)
            .then((approved: boolean) => {
              if (hintButtonRef.current) {
                hintButtonRef.current.blur()
              }
              setCheckAllowance(true)
            })
            .catch((error: any) => {
              console.log(error)
            })
        } else {
          // buy
          huntContract?.purchaseHint(BigNumber.from(riddleData.id))
            .then(() => {
              if (hintButtonRef.current) {
                hintButtonRef.current.blur()
              }
              setCheckHasPurchasedHint(true)
            })
            .catch((error: any) => {
              console.log(error)
            })
        }
      }
    }
  }

  const handleHint2Click = () => {
    if (hasPurchasedHint2) {
      if (account && account != null && library) {
        const nonce = makeNonce()
        library
          .send('personal_sign', [account, `${Buffer.from(nonce, 'utf8').toString('hex')}`])
          .then(signature => {
            const params : HintParams = {
              address: account,
              riddle_id: riddleData.id,
              signature: signature,
              nonce: nonce,
            }
            axios.post(API_URL + '/treasure-hunt/hint2', params)
              .then(response => {
                setHint2Message(response.data.hint)
                setResultMessage(response.data.result)
                if (hintButtonRef.current) {
                  hintButtonRef.current.blur()
                }
              })
          })
      }
    } else {
      if (hint2Price && !hint2Price.isZero()) {
        if (shibxAllowance2.lt(hint2Price)) {
          // approve
          shibavaxContract?.approve(HUNT_CONTRACT2_ADDRESS, hint2Price)
            .then((approved: boolean) => {
              if (hint2ButtonRef.current) {
                hint2ButtonRef.current.blur()
              }
              setCheckAllowance2(true)
            })
            .catch((error: any) => {
              console.log(error)
            })
        } else {
          // buy
          huntContract2?.purchaseHint(BigNumber.from(riddleData.id))
            .then(() => {
              if (hint2ButtonRef.current) {
                hint2ButtonRef.current.blur()
              }
              setCheckHasPurchasedHint2(true)
            })
            .catch((error: any) => {
              console.log(error)
            })
        }
      }
    }
  }

  const handleClick = () => {
    if (account && account != null && library) {
      let formattedAnswer = `${Buffer.from(riddleAnswer, 'utf8').toString('hex')}`
      if (formattedAnswer.length === 42) {
        formattedAnswer = `0x${formattedAnswer}`
      }

      library
        .send('personal_sign', [account, formattedAnswer])
        .then(signature => {
          const params : RiddleParams = {
            address: account,
            riddle_id: riddleData.id,
            answer: riddleAnswer,
            signature: signature,
          }
          axios.post(API_URL + '/treasure-hunt/riddle', params)
            .then(response => {
              if (response.data.riddle) {
                setRiddleData(response.data.riddle)
              }
              setRiddleAnswer("")
              setHintMessage("")
              setHint2Message("")
              setResultMessage(response.data.result)
              if (answerButtonRef.current) {
                answerButtonRef.current.blur()
              }
            })
        })
    }
  }

  return (
    <>
      <Route component={GoogleAnalyticsReporter} />
      <ShibavaxHeader/>
      <Web3ReactManager>
        <AppWrapper>
          {!account && (
            <Row>
              <Regular style={{
                marginLeft: "10%",
                marginRight: "10%",
                marginTop: "2%",
                marginBottom: "2%"
              }}>
                Click below to connect to a wallet address that has more than 500K SHIBX to get started.
              </Regular>
            </Row>
          )}
          {account && shibxBalance.gte(BigNumber.from(500000)) && (
            <>
            {riddleData !== defaultRiddleData && (
              <>
                <Row>
                  <RiddleTitle style={{ marginTop: "5%" }}>
                    Level #{riddleData.id + 1}
                  </RiddleTitle>
                </Row>
                <Divider/>
              </>
             )}
             {riddleData !== defaultRiddleData && (
               <Row style={{ width: "100%" }}>
                 <Regular
                    style={{
                      marginLeft: "10%", marginRight: "10%", marginTop: "2%", marginBottom: "2%", width: "100%", textAlign: "center"}}>
                   {riddleData.text}
                 </Regular>
               </Row>
             )}
             {riddleData.url_type === 'audio' && (
               <Row>
                 <ReactAudioPlayer
                   src={riddleData.url}
                   autoPlay
                   controls
                 />
               </Row>
             )}
             {riddleData.url_type === 'image' && (
               <Row>
                 <img src={riddleData.url} alt="riddle" style={{width: "50%", marginLeft: "auto", marginRight: "auto"}}/>
               </Row>
             )}
             {riddleData !== defaultRiddleData && (
               <>
                 <Divider/>
                 <Row style={{ display: "flex", alignItems: "center" }}>
                   <Col xs={8} >
                     <Input
                       style={{
                         width: "100%",
                         paddingLeft: "0px",
                         paddingRight: "0px",
                         marginBottom: "0px",
                         borderRadius: "85px",
                         fontFamily: "MontSerrat",
                         fontStyle: "normal",
                         fontSize: "30px",
                         fontWeight: 500,
                       }}
                       type="text"
                       autoComplete="off"
                       autoCorrect="off"
                       autoCapitalize="off"
                       spellCheck="false"
                       placeholder="answer"
                       value={riddleAnswer}
                       onChange={event => setRiddleAnswer(event.target.value)}
                     />
                   </Col>
                   <Col>
                     <RedButton
                         style={{
                           width: "100%",
                           paddingLeft: "0px",
                           paddingRight: "0px",
                         }}
                         onClick={handleClick}
                         ref={answerButtonRef}>
                       Try it!
                     </RedButton>
                   </Col>
                 </Row>
               </>
             )}
             <Row style={{ marginTop: "2%" }}>
               <RegularRed style={{ marginTop: "2%", width: "100%", textAlign: "center" }}>
                 {resultMessage}
               </RegularRed>
             </Row>
             {riddleData.hint && hasPurchasedHint && (
               <>
                 <Divider/>
                 <AdjustedRow>
                   <AdjustedCol xs={8}>
                     <Regular
                         style={{
                           width: "100%",
                           paddingLeft: "0px",
                           paddingRight: "0px",
                           verticalAlign: "middle",
                           textAlign: "right",
                         }}>
                       You bought the hint for this one
                     </Regular>
                   </AdjustedCol>
                   <AdjustedCol>
                     <RedButton onClick={handleHintClick} ref={hintButtonRef}>
                       See hint
                     </RedButton>
                   </AdjustedCol>
                 </AdjustedRow>
                 <AdjustedRow>
                   <RegularRed
                         style={{
                           width: "100%",
                           paddingLeft: "0px",
                           paddingRight: "0px",
                           marginTop: "2%",
                           verticalAlign: "middle",
                           textAlign: "center",
                         }}>
                     {hintMessage}
                   </RegularRed>
                 </AdjustedRow>
               </>
             )}
             {riddleData.hint && !hasPurchasedHint && hintPrice && (
               <>
                 <Divider/>
                 <AdjustedRow style={{width: "fit-content"}}>
                   <Col
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          verticalAlign: "middle",
                        }}
                       >
                     <Regular
                         style={{
                           width: "100%",
                           paddingLeft: "0px",
                           paddingRight: "0px",
                           verticalAlign: "middle",
                           textAlign: "right",
                         }}>
                       Need a hint ? {!hintPrice.isZero() ? "(" + hintPrice.div(ONE_K_SHIBX).toString() + "K SHIBX)" : ""}
                     </Regular>
                   </Col>
                   <AdjustedCol style={{width: "fit-content"}}>
                     <RedButton
                         style={{
                           width: "100%",
                           paddingLeft: "0px",
                           paddingRight: "0px",
                         }}
                         onClick={handleHintClick} ref={hintButtonRef}>
                     {shibxAllowance.lt(hintPrice) ? "Approve" : "Buy"}
                     </RedButton>
                   </AdjustedCol>
                 </AdjustedRow>
               </>
             )}
             {riddleData.hint2 && hasPurchasedHint && hasPurchasedHint2 && (
               <>
                 <Divider/>
                 <AdjustedRow>
                   <AdjustedCol xs={8}>
                     <Regular
                         style={{
                           width: "100%",
                           paddingLeft: "0px",
                           paddingRight: "0px",
                           verticalAlign: "middle",
                           textAlign: "right",
                         }}>
                       You bought the extra hint for this one
                     </Regular>
                   </AdjustedCol>
                   <AdjustedCol>
                     <RedButton onClick={handleHint2Click} ref={hint2ButtonRef}>
                       See hint
                     </RedButton>
                   </AdjustedCol>
                 </AdjustedRow>
                 <AdjustedRow>
                   <RegularRed
                         style={{
                           width: "100%",
                           paddingLeft: "0px",
                           paddingRight: "0px",
                           marginTop: "2%",
                           verticalAlign: "middle",
                           textAlign: "center",
                         }}>
                     {hint2Message}
                   </RegularRed>
                 </AdjustedRow>
               </>
             )}
             {riddleData.hint2 && hasPurchasedHint && !hasPurchasedHint2 && hint2Price && (
               <>
                 <Divider/>
                 <AdjustedRow style={{width: "fit-content"}}>
                   <Col
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          verticalAlign: "middle",
                        }}
                       >
                     <Regular
                         style={{
                           width: "100%",
                           paddingLeft: "0px",
                           paddingRight: "0px",
                           verticalAlign: "middle",
                           textAlign: "right",
                         }}>
                       Need an extra hint ? {!hint2Price.isZero() ? "(" + hint2Price.div(ONE_K_SHIBX).toString() + "K SHIBX)" : ""}
                     </Regular>
                   </Col>
                   <AdjustedCol style={{width: "fit-content"}}>
                     <RedButton
                         style={{
                           width: "100%",
                           paddingLeft: "0px",
                           paddingRight: "0px",
                         }}
                         onClick={handleHint2Click} ref={hint2ButtonRef}>
                     {shibxAllowance2.lt(hint2Price) ? "Approve" : "Buy"}
                     </RedButton>
                   </AdjustedCol>
                 </AdjustedRow>
               </>
             )}
          </>
          )}
          {account && shibxBalance.lt(BigNumber.from(500000)) && (
            <Row>
              <Regular>
                You don't have the minimum 500K SHIBX required to participate, go to
                baguette.exchange to buy some tokens then come back here!
              </Regular>
            </Row>
          )}
          <Divider/>
          <Row>
            <Web3Status/>
          </Row>
          </AppWrapper>
        </Web3ReactManager>
      </>
    );
}

export default App;
