import React, { Component } from 'react'
import ShibavaxLogo from './assets/Logo-outline-HD.png'
import ShibavaxLogo2x from './assets/Logo-outline-HD@2x.png'
import './css/header.css'
import './css/betheme-style.css'
import './css/betheme-css-base.css'
import './css/betheme-css-layout.css'
import './css/betheme-css-shortcodes.css'
import './css/betheme-assets-animations-animations.min.css'
import './css/betheme-assets-ui-jquery.ui.all.css'
import './css/betheme-assets-jplayer-css-jplayer.blue.monday.css'
import './css/betheme-css-responsive.css'
import './css/8810.css'
import './css/mfn-header-builder-functions-assets-style.css'

class Header extends Component {
  render() {
    return (
      <div id="Header_wrapper" class="" >
  	    <header class="mhb-grid" data-desktop="custom auto">
          <div class="mhb-placeholder">
          </div>
          <div class="mhb-view desktop default wrap_into_grid">
            <div class="mhb-row first-row">
              <div class="mhb-row-wrapper container">
                <div class="mhb-col left">
                  <div class="mhb-item mhb-logo mhb-custom-wx33lhQ8b">
                    <div class="mhb-item-inner ">
                      <h1>
                        <a href="https://shibavax.io" title="SHIBAVAX">
                          <img class="logo" src={ShibavaxLogo} alt="shibavax logo" data-retina={ShibavaxLogo2x} height="50" style={{height: '50px'}}/>
                        </a>
                      </h1>
                    </div>
                  </div>
                  <div class="mhb-item mhb-menu mhb-custom-1lpbCtYsf">
                    <div class="mhb-item-inner tabletMobile">
                      <ul id="menu-main-menu" class="menu">
                        <li id="menu-item-121" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-39">
                          <a href="https://shibavax.io/#hunt">
                            <span>HUNT</span>
                          </a>
                        </li>
                        <li id="menu-item-36" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-36">
                          <a href="https://shibavax.io/#download">
                            <span>TOKENOMICS</span>
                          </a>
                        </li>
                        <li id="menu-item-72" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-72">
                          <a href="https://shibavax.io/#forces">
                            <span>OUR STRENGTHS</span>
                          </a>
                        </li>
                        <li id="menu-item-39" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-39">
                          <a href="https://shibavax.io/#buy">
                            <span>BUY</span>
                          </a>
                        </li>
                        <li id="menu-item-71" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-71">
                          <a href="https://shibavax.io/#faq">
                            <span>FAQ</span>
                          </a>
                        </li>
                        <li id="menu-item-120" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-71">
                          <a href="https://nft.shibavax.io">
                            <span>NFTs</span>
                          </a>
                        </li>
                      </ul>
                      <a class="mobile-menu-toggle" href="#">
                        <i class="icon-menu-fine"></i>
                      </a>
                    </div>
                  </div>
                </div>
                <div class="mhb-col center"></div>
                <div class="mhb-col right">
                  <div class="mhb-item mhb-social mhb-custom-pxtOMaLWz">
                    <ul>
                      <li>
                        <a href="https://t.me/shibavax" >
                          <i class="icon-paper-plane"></i>
                        </a>
                      </li>
                      <li>
                        <a href="https://twitter.com/shibavax" >
                          <i class="icon-twitter"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>
      </div>
    );
  }
}

export default Header;
